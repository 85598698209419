<template>
  <Menubar />
  <MainContainer>
    <!-- TOP -->
    <template v-slot:header>
      <button class="a-main-btn" @click="settings.addModal = true">
        Agregar
      </button>
      <div class="a-search-container">
        <InputText placeholder="Buscar" v-model="filters['global'].value" />
        <!-- <button class="a-icon-btn">
          <div>
            <img :src="require('@/assets/icons/search-white.svg')" />
          </div>
        </button> -->
      </div>
    </template>

    <DataTable
      :value="users"
      :paginator="true"
      :rows="8"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink"
      :filters="filters"
      :globalFilterFields="[
        'username',
        'first_name',
        'last_name',
        'userType.name',
        'email',
      ]"
    >
      <!-- Fields defined in data section -->
      <Column
        v-for="col of fields"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        sortable
      />

      <!-- Action buttons -->
      <Column headerStyle="width: 9rem">
        <template #body="slotProps">
          <div class="a-container-tab-btns">
            <button
              class="a-icon-tab-btn a-detail-btn"
              @click="detailUser(slotProps.data)"
            >
              <img :src="require('@/assets/icons/eye-white.svg')" />
            </button>
            <button
              class="a-icon-tab-btn a-delete-btn"
              @click="deleteUser(slotProps.data)"
              :disabled="!slotProps.data.is_active"
            >
              <img :src="require('@/assets/icons/cross.svg')" />
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </MainContainer>

  <Dialog
    header="Añadir usuario"
    v-model:visible="settings.addModal"
    v-if="settings.addModal"
    :modal="true"
    :breakpoints="{ '960px': '75vw' }"
    :style="{ width: '80vw' }"
  >
    <NewUser @onSuccess="userAdded" @onExit="settings.addModal = false" />
  </Dialog>

  <Dialog
    v-model:visible="settings.detailModal"
    v-if="settings.detailModal"
    :modal="true"
    :breakpoints="{ '960px': '75vw' }"
    :style="{ width: '50vw' }"
  >
    <template #header>
      <h3>
        Usuario <span class="a-id-badge">{{ settings.user.id }}</span>
      </h3>
    </template>
    <DetailUser :item="settings.user" :isEdit="false" />
  </Dialog>

  <ConfirmDialog />

  <Toast />
</template>

<script>
import Menubar from "@/components/navigation/Top.vue";
import MainContainer from "@/components/secure/MainContainer.vue";
import UserAPI from "@/services/users.js";
import NewUser from "@/components/auth/NewUser.vue";
import DetailUser from "@/components/auth/DetailUser.vue";
import { FilterMatchMode } from "primevue/api";

export default {
  name: "Users",
  components: {
    Menubar,
    MainContainer,
    NewUser,
    DetailUser,
  },
  data() {
    return {
      users: [],
      fields: [
        {
          field: "username",
          header: "Usuario",
        },
        {
          field: "first_name",
          header: "Nombre",
        },
        {
          field: "userType.name",
          header: "Tipo",
        },
        {
          field: "email",
          header: "Correo electrónico",
        },
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      settings: {
        addModal: false,
        detailModal: false,
        user: null,
      },
    };
  },
  methods: {
    getUsers() {
      UserAPI.index()
        .then((response) => {
          this.users = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.getUsers();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    userAdded() {
      this.settings.addModal = false;
      this.getUsers();
      this.$toast.add({
        severity: "success",
        detail: "Se ha añadido un nuevo usuario",
        life: 3000,
      });
    },
    detailUser(item) {
      this.settings.user = item;
      this.settings.detailModal = true;
    },
    deleteUser(item) {
      let message = `¿Estás seguro de eliminar el usuario ${item.username}?`;
      this.$confirm.require({
        message: message,
        header: "Eliminar usuario",
        acceptLabel: "Aceptar",
        acceptClass: "p-button-danger",
        rejectLabel: "Cancelar",
        accept: () => {
          //callback to execute when user confirms the action
          UserAPI.delete(item)
            .then(() => {
              this.getUsers();
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response) {
                let response = error.response.data;
                let errors = Object.keys(response);
                let message = "";
                let isTokenError = false;
                errors.forEach((err) => {
                  message += response[err];
                  if (err == "code") {
                    if (response[err] == "token_not_valid") {
                      isTokenError = true;
                      UserAPI.updateToken().then((response) => {
                        sessionStorage.setItem("cfta", response.data.access);
                        this.deleteUser(item);
                      });
                    }
                  }
                });
                if (!isTokenError) {
                  this.$toast.add({
                    severity: "error",
                    summary: "Ha ocurrido un error",
                    detail: message,
                    life: 3000,
                  });
                }
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Ha ocurrido un error. Inténtalo de nuevo",
                  detail: error.message,
                  life: 3000,
                });
              }
            });
        },
      });
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<style></style>
