<template>
  <div id="a-detail-user-content">
    <div>
      <InputText
        type="text"
        v-model="localItem.first_name"
        placeholder="Nombre"
        :disabled="!isEdit"
      />
      <InputText
        type="text"
        v-model="localItem.last_name"
        placeholder="Apellido"
        :disabled="!isEdit"
      />
      <InputText
        type="text"
        v-model="localItem.email"
        placeholder="Correo electrónico"
        :disabled="!isEdit"
      />

      <InputText
        type="text"
        v-model="localItem.userType.name"
        placeholder="Tipo de usuario"
        :disabled="!isEdit"
      />
    </div>
    <div
      id="a-user-card"
      :class="localItem.is_active ? 'a-active-user' : 'a-inactive-user'"
      @click="copyInfo()"
    >
      <div>
        <img :src="require('@/assets/images/auth/user.svg')" />
      </div>
      <div id="a-user-info">
        <div>
          <span><strong>Usuario</strong></span>
          <br />
          <span>{{ localItem.username }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isEdit: Boolean,
    item: Object,
  },
  data() {
    return {
      localItem: {
        username: "",
        email: "",
        password: "123AB$",
        first_name: "",
        secondName: "",
        last_name: "",
        secondSurname: "",
        userType: { name: "" },
        is_active: true,
      },
    };
  },
  emits: ["onSuccess", "onExit"],
  mounted() {
    this.localItem = this.item;
  },
  methods: {
    async copyInfo() {
      await navigator.clipboard.writeText(
        `Usuario: ${this.localItem.username}`
      );
      alert(
        `Se copiaron las credenciales\nUsuario: ${this.localItem.username}`
      );
    },
  },
};
</script>

<style scoped>
#a-detail-user-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}
#a-user-card {
  /* cursor: url("@/assets/icons/pencil.svg"), auto; */
  background: var(--color-1);
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  color: white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding: 18px 30px;
}
#a-user-card:hover {
  cursor: copy;
}
#a-user-info {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.a-active-user {
  border: 5px solid var(--color-3);
}
.a-inactive-user {
  border: 5px solid #d90024;
}
</style>
